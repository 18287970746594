<template>
  <div class="form-div">
     <div><Checkbox v-model="isCheck" @on-change="changeCheck">残疾人信息</Checkbox></div>
     <Form v-show="isCheck" class="isForm" label-position="right" :label-width="150">
        <FormItem class="isNoWidth">
              <span slot="label">残疾证号:</span>
              <Input placeholder="请输入残疾证号" :maxlength="30" v-model="formDataDisabled.disabledNo" />
        </FormItem>
         <FormItem class="isWidth">
             <span slot="label">残疾类型:</span>
              <Select clearable v-model="formDataDisabled.disabledType" >
                <Option
                v-for="item in disabledType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">残疾车车牌:</span>
              <Input placeholder="请输入残疾车车牌" :maxlength="30"  v-model="formDataDisabled.licencePlate" />
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">残疾人辖内外情况:</span>
              <Select clearable v-model="formDataDisabled.areaIn" >
                <Option
                v-for="item in areaInType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">残疾人社保状况:</span>
              <Select clearable v-model="formDataDisabled.socialSecurity" >
                <Option
                v-for="item in socialSecurityType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">残疾人医保状况:</span>
              <Select clearable v-model="formDataDisabled.healthCare" >
                <Option
                v-for="item in healthCareType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">残疾人人员变动情况:</span>
              <Input placeholder="请输入残疾人人员变动情况" type="textarea" v-model="formDataDisabled.personnelChange" />
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">残疾人信息备注:</span>
              <Input placeholder="请输入残疾人信息备注" type="textarea" v-model="formDataDisabled.disabledDesc" />
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">接收残疾人慰问情况:</span>
              <Input placeholder="请输入接收残疾人慰问情况" type="textarea" v-model="formDataDisabled.condoleDesc" />
        </FormItem>
     </Form>
  </div>
</template>

<script>
export default {
  props:{
    disabliCheck:{
      type:Boolean,
      default:()=>{
        return false
      }
    },
    formDataDisabled:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    userId:{
            type:Object,
            default:()=>{
                return ''
            }
        },
  },
  data(){
    return{
      isCheck:false,
      areaInType:[
        // {dictKey:'1',dictValue:'辖内'},
        // {dictKey:'2',dictValue:'辖外'},
        // {dictKey:'3',dictValue:'住院'},
      ],
      socialSecurityType:[
        // {dictKey:'1',dictValue:'未购买'},
        // {dictKey:'2',dictValue:'居民养老'},
        // {dictKey:'3',dictValue:'职工养老'},
      ],
      healthCareType:[
        // {dictKey:'1',dictValue:'未购买'},
        // {dictKey:'2',dictValue:'居民医保'},
        // {dictKey:'3',dictValue:'职工医保'},
      ],
      disabledType:[],
    }
  },
  created(){
    this.getDicType('DISABILITY_STATUS','disabledType')
    this.getDicType('DISABILITY_SITUATION','areaInType')
    this.getDicType('DISABLED_SOCIAL_SECURITY','socialSecurityType')
    this.getDicType('DISABLED_MEDICAL_INSURANCE','healthCareType')
    this.getDetail()
     if(this.disabliCheck == true){
      this.isCheck = true
    }
  },
  methods:{ 
    changeCheck(val){
      this.isCheck = val
      this.$emit('changeDiable',val)
      // console.log(val);
    },
    // 获取字典接口的公共方法
        getDicType(type,model){
            this.$get('/voteapi/api/common/sys/findDictByDictType',{
                dictType:type
            }).then(res=>{
                this[model] = res.dataList
            })
        },
        getDetail(){
          if(this.userId && this.userId != ''){
            this.$get('/syaa/api/syuser/pc/residentArchive/selectArchiveDisabled',{
              userId:this.userId,
              // familyId:this.familyId
            }).then(res=>{
              if(res.code == 200){
                  this.$emit('getDisabled',res.data)
              }else{
                this.$Message.error({
                  content:'孤独老人信息获取失败',
                  background:true
                })
                return
              }
            })
          }
          
        }
  },
   watch:{
     disabliCheck:{
       hanlder(val,newVal){
         if(val){
           this.isCheck = val
         }
       }
     }
   }
}
</script>

<style lang="less" scoped>
.form-div{
    width:100%;
    user-select: none;
    .isForm{
        display: flex;
        flex-wrap: wrap;
        .isWidth{
        width: 40%;
        margin-right: 40px;
        }
        .isNoWidth{
            width: 100%;
        }
    }
    
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
/deep/.ivu-date-picker{
    display: inline;
}
</style>