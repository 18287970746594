<template>
  <div class="form-div">
     <div><Checkbox v-model="isCheck" @on-change="changeCheck">党员信息</Checkbox></div>
     <Form v-show="isCheck" class="isForm" label-position="right" :label-width="170">
        <FormItem class="isNoWidth">
              <span slot="label">所在党委:</span>
              <Input placeholder="请输入您所在的党委" :maxlength="20" v-model="formDataParty.partyCommittee" />
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">所在党支部:</span>
              <Input placeholder="请输入您所在的党支部" :maxlength="20" v-model="formDataParty.partyBranch"  />
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">党内职务:</span>
              <Select clearable v-model="formDataParty.post">
                <Option
                v-for="item in postType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
        </FormItem>
        <FormItem class="isWidth">
              <span slot="label">是否加入党员服务队:</span>
              <i-switch  v-model="formDataParty.joinService"  />
        </FormItem>
        <FormItem class="isWidth">
              <span slot="label">能参加社区服务的时间:</span>
              <Select clearable v-model="formDataParty.serviceTime" >
                <Option
                v-for="item in serviceTimeType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
        </FormItem>
        <FormItem class="isWidth">
              <span slot="label">转入支部类型:</span>
              <Select clearable v-model="formDataParty.joinType" >
                <Option
                v-for="item in joinType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
        </FormItem>
        <FormItem class="isWidth">
              <span slot="label">转入支部时间:</span>
              <DatePicker type="date" v-model="formDataParty.joinDate"></DatePicker>
        </FormItem>
        <FormItem class="isWidth">
              <span slot="label">转出支部类型:</span>
              <Select clearable v-model="formDataParty.leaveType" >
                <Option
                v-for="item in leaveType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
        </FormItem>
        <FormItem class="isWidth">
              <span slot="label">转出支部时间:</span>
              <DatePicker type="date" v-model="formDataParty.leaveDate"></DatePicker>
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">入党时间:</span>
              <DatePicker type="date" v-model="formDataParty.memberDate"></DatePicker>
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">入党转正时间:</span>
              <DatePicker type="date" v-model="formDataParty.memberBecome"></DatePicker>
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">单位党组织联系电话:</span>
              <Input placeholder="请输入单位党组织联系电话" :maxlength="20" v-model="formDataParty.unitTel" />
              <!-- <DatePicker type="date" v-model="formDataParty.unitTel"></DatePicker> -->
        </FormItem>
     </Form>
  </div>
</template>

<script>
export default {
  props:{
    partyCheck:{
      type:Boolean,
      default:()=>{
        return false
      }
    },
    formDataParty:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    userId:{
            type:Object,
            default:()=>{
                return ''
            }
        },
  },
  data(){
    return{
      postType:[
        // {dictKey:'1',dictValue:'成员'},
        // {dictKey:'2',dictValue:'委员'},
        // {dictKey:'3',dictValue:'副书记'},
        // {dictKey:'4',dictValue:'书记'},
      ],//党内职务
      serviceTimeType:[
        // {dictKey:'1',dictValue:'工作日'},
        // {dictKey:'2',dictValue:'周末'},
        // {dictKey:'3',dictValue:'不限'},
      ],//能参加社区服务时间
      isCheck:false,
      joinType:[],
      leaveType:[]
    }
  },
  created(){
    this.getDicType('COMMUNSIT_JOIN_TYPE','joinType')
    this.getDicType('COMMUNSIT_LEAVE_TYPE','leaveType')
    this.getDicType('COMMUNIST_POST','postType')
    this.getDicType('COMMUNITY_SERVICE','serviceTimeType')
    this.getDetail()
    if(this.partyCheck == true){
      this.isCheck = true
    }
  },
  methods:{ 
    changeCheck(val){
      this.isCheck = val
      this.$emit('changeCheck',val)
      // console.log(val);
    },
      getDicType(type,model){
            this.$get('/voteapi/api/common/sys/findDictByDictType',{
                dictType:type
            }).then(res=>{
                this[model] = res.dataList
            })
        },
        getDetail(){
          if(this.userId && this.userId != ''){
            this.$get('/syaa/api/syuser/pc/residentArchive/selectArchiveCommunist',{
              userId:this.userId,
              // familyId:this.familyId
            }).then(res=>{
              if(res.code == 200){
                  if(res.data.joinService == 1) res.data.joinService = true
                  this.$emit('getParty',res.data)
              }else{
                this.$Message.error({
                  content:'党员信息获取失败',
                  background:true
                })
                return
              }
            })
          }
          
        }
  },
   watch:{
     partyCheck:{
       hanlder(val,newVal){
         if(val){
           this.isCheck = val
           console.log('如果val改变',val);
         }
       }
     }
   }
}
</script>

<style lang="less" scoped>
.form-div{
    width:100%;
    user-select: none;
    .isForm{
        display: flex;
        flex-wrap: wrap;
        .isWidth{
        width: 40%;
        margin-right: 40px;
        }
        .isNoWidth{
            width: 100%;
        }
    }
    
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
/deep/.ivu-date-picker{
    display: inline;
}
</style>