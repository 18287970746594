<template>
  <div class="form-div">
     <div><Checkbox v-model="isCheck" @on-change="changeCheck">退役军职信息</Checkbox></div>
     <Form v-show="isCheck" class="isForm" label-position="right" :label-width="150">
        <FormItem class="isNoWidth">
              <span slot="label">军职类别:</span>
              <Select clearable v-model="formDataRetire.type" >
                <Option
                v-for="item in Type"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">是否参战:</span>
              <RadioGroup v-model="formDataRetire.fight">
                <Radio label="1">
                    是
                </Radio>
                <Radio label="2">
                    否
                </Radio>
             
          </RadioGroup>
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">原部队编号:</span>
              <Input placeholder="请输入原部队编号"  :maxlength="30" v-model="formDataRetire.armyNo" />
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">军职信息备注:</span>
              <Input placeholder="请输入军职信息备注" type="textarea" v-model="formDataRetire.militaryDesc" />
        </FormItem>
        <FormItem class="isNoWidth">
              <span slot="label">服役情况:</span>
              <Input placeholder="请输入服役情况" type="textarea" v-model="formDataRetire.serviceDesc" />
        </FormItem>
     </Form>
  </div>
</template>

<script>
export default {
  props:{
    retireCheck:{
      type:Boolean,
      default:()=>{
        return false
      }
    },
    formDataRetire:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    userId:{
            type:Object,
            default:()=>{
                return ''
            }
        },
  },
  data(){
    return{
      isCheck:false,
      Type:[
        // {dictKey:'1',dictValue:'退役士兵'},
        // {dictKey:'2',dictValue:'军队转业干部'},
      ],
    }
  },
  created(){
    this.getDicType('MILITARY_CATEGORY','Type')
    this.getDetail()
    if(this.retireCheck == true){
      this.isCheck = true
    }
  },
  methods:{ 
    changeCheck(val){
      this.isCheck = val
      this.$emit('changeRetire',val)
      // console.log(val);
    },
     getDetail(){
          if(this.userId && this.userId != ''){
            this.$get('/syaa/api/syuser/pc/residentArchive/selectArchiveRetirement',{
              userId:this.userId,
              // familyId:this.familyId
            }).then(res=>{
              if(res.code == 200){
                  this.$emit('getRetire',res.data)
              }else{
                this.$Message.error({
                  content:'退役军职信息获取失败',
                  background:true
                })
                return
              }
            })
          }
          
        },
          // 获取字典接口的公共方法
        getDicType(type,model){
            this.$get('/voteapi/api/common/sys/findDictByDictType',{
                dictType:type
            }).then(res=>{
                this[model] = res.dataList
            })
        },
  },
   watch:{
     retireCheck:{
       hanlder(val,newVal){
         if(val){
           this.isCheck = val
         }
       }
     }
   }
}
</script>

<style lang="less" scoped>
.form-div{
    width:100%;
    user-select: none;
    .isForm{
        display: flex;
        flex-wrap: wrap;
        .isWidth{
        width: 40%;
        margin-right: 40px;
        }
        .isNoWidth{
            width: 100%;
        }
    }
    
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
/deep/.ivu-date-picker{
    display: inline;
}
</style>