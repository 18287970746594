<template>
  <div class="add-container">
      <div class="add-bottom">
          <div class="bottom-menu">
               <Menu theme="light" :active-name="menuName" @on-select="selectMenu">
                   <CheckboxGroup v-model="checkList">
                            <MenuItem name="1">
                                <Icon type="ios-information-circle" />
                                <Checkbox label="base">基本信息</Checkbox>
                            </MenuItem>
                            <MenuItem name="2">
                                <Checkbox label="personal">人员详细信息</Checkbox>                
                            </MenuItem>
                            <MenuItem name="3">
                            <Icon type="ios-information-circle" />
                             <Checkbox label="home">网上家园信息</Checkbox>
                            </MenuItem>
                            <MenuItem name="4">
                            <Checkbox label="family">家庭成员信息</Checkbox>
                            </MenuItem>
                            <MenuItem name="5">
                            <Checkbox label="party">党员信息</Checkbox>
                            </MenuItem>
                            <MenuItem name="6">
                            <Checkbox label="volunteer">志愿者信息</Checkbox>
                            </MenuItem>
                            <MenuItem name="7">
                            <Checkbox label="resident">居民小组信息</Checkbox>
                            </MenuItem>
                            <MenuItem name="8">
                            <Checkbox label="healthy">健康需求评估</Checkbox>
                            </MenuItem>
                            <MenuItem name="9">
                            <Checkbox label="special">特殊困难群体信息采集</Checkbox>
                            </MenuItem>
                            <MenuItem name="10">
                            <Checkbox label="allowance">低保申请信息</Checkbox>
                            </MenuItem>
                            <MenuItem name="11">
                            <Checkbox label="lonely">孤独老人信息</Checkbox>
                            </MenuItem>
                            <MenuItem name="12">
                            <Checkbox label="disability">残疾人信息</Checkbox>
                            </MenuItem>
                            <MenuItem name="13">
                            <Checkbox label="retire">退役军职信息</Checkbox>
                            </MenuItem>
                            <MenuItem name="14">
                            <Checkbox label="vaccines">疫情防控档案信息</Checkbox>
                            </MenuItem>
                    </CheckboxGroup>
               </Menu>
          </div>
          <div class="bottom-container">
              <keep-alive>
                <component :is="showMenu"></component>
               </keep-alive>
          </div>
          <div class="bottom-right">
              <div class="btn-div">
                  <div>&nbsp;<Button type="primary" @click="changeUp" v-if="menuName != '1'">上一个</Button></div>
                  <div>&nbsp;<Button type="primary" @click="changeDown" v-if="menuName != '14'">下一个</Button></div>
                  <div><Button type="primary" @click="changeClose">关闭</Button></div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import Base from '../setForm/base.vue'
import Personal from '../setForm/personal'
import Home from '../setForm/home'
import Family from '../setForm/family'
import Party from '../setForm/party'
import Volunteer from '../setForm/volunteer'
import Resident from '../setForm/resident'
import Healthy from '../setForm/healthy'
import Special from '../setForm/special'
import Allowance from '../setForm/allowance'
import Lonely from '../setForm/lonely'
import Disability from '../setForm/disability'
import Retire from '../setForm/retire'
import Vaccines from '../setForm/vaccines'
 export default {
    components:{Base,Personal,Home,Family,Party,Volunteer,Resident,Healthy,Special,Allowance,Lonely,Disability,Retire,Vaccines},
    data(){
        return{
        
            menuName:'1',
            showMenu:'Base',
            formData:{
                userHomeDetail:{},//基本信息
                userHomeInfo:{},//人员详细信息
                userHomeArchive:{},//网上家园信息
                archiveCommunistRo:{},//党员信息
                archiveVolunteerRo:{},//志愿者信息
                userRegionGroupRo:{},//居民小组信息
                userHealthRo:{}, //健康需求评估
                archiveHardRo:{},//特殊群体
                archiveHardItemRoList:[],//特殊群体内选项选择的内容
                archiveBasiclivingRo:{}, //低保申请信息
                archiveLonelyRo:{},//孤独老人
                archiveDisabledRo:{},//残疾人信息
                archiveRetirementRo:{},//退役军人信息
                archiveVaccinationRo:{},//疫苗接种情况
                vaccinationDetailRoList:[{
                        vaccinationDate:'',
                        brand:'',
                        batch:'0'
                    },
                    {
                        vaccinationDate:'',
                        brand:'',
                        batch:'1'
                    },
                    {
                        vaccinationDate:'',
                        brand:'',
                        batch:'2'
                    }],//接种时间
                archiveNatRoList:[{natDate:'',natResult:''}], //核酸检测情况
                // custGlobalId: "string,用户全局ID、新增则不需要传入",
                // familyId: "string,家庭ID",
                // familyMemberId: "string,用户家庭成员ID、新增则不需要传入",
                operCustGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                operCustGlobalName:parent.vue.loginInfo.userinfo.realName
                // orgCode: "string,用户所属的机构代码",
            },
            checkList:[],
        }
    },
    mounted(){},
    methods:{
        // 点击了左边菜单事件
        selectMenu(name){
            this.isName(name)
            this.menuName = name     
        },
        // 上一个
        changeUp(){
            console.log(this.menuName);
            this.menuName = String(Number(this.menuName)-1)
            this.isName(this.menuName)
        },
        // 下一个
        changeDown(){
            console.log(this.menuName);
            this.menuName = String(Number(this.menuName)+1)
             this.isName(this.menuName)
        },
        // 关闭
        changeClose(){
            this.$router.push('/linehomeindex')
        },
        // // 保存
        // changeSave(){
        //    this.$router.push('/linehomeindex')
        // },
        // isName
        isName(name){
            if(name == '1') return this.showMenu = 'Base'
            if(name == '2') return this.showMenu = 'Personal'
            if(name == '3') return this.showMenu = 'Home'
            if(name == '4') return this.showMenu = 'Family'
            if(name == '5') return this.showMenu = 'Party'
            if(name == '6') return this.showMenu = 'Volunteer'
            if(name == '7') return this.showMenu = 'Resident'
            if(name == '8') return this.showMenu = 'Healthy'
            if(name == '9') return this.showMenu = 'Special'
            if(name == '10') return this.showMenu = 'Allowance'
            if(name == '11') return this.showMenu = 'Lonely'
            if(name == '12') return this.showMenu = 'Disability'
            if(name == '13') return this.showMenu = 'Retire'
            if(name == '14') return this.showMenu = 'Vaccines'
        },

        // 基础表格的事件
        changeName(val){
                this.headerData[0].name = val     
        },  
 
    }
}
</script>

<style lang="less" scoped>
.add-container{
    width: 100%;
    height: 100%;
    // border: 1px solid red;
    padding: 20px;
    .add-top{
        width:100%;
        .top-header{
            display: flex;
            .table{
                flex: 1;
            }
            .btn-div{
                margin-left: auto;
                padding-left: 20px;
            }
        }
    }
    .add-bottom{
        background: #ffffff;
        margin-top: 20px;
        width: 100%;
        height: 90%;
        border: 1px solid #cccccc;
        display: flex;
        justify-content: flex-start;
        .bottom-menu{
            border-left: 1px solid #cccccc;
            position: relative;
        }
        .bottom-container{
            height: 100%;    
            flex: 1;
            overflow-y: scroll;
            padding: 20px;
        }
        .bottom-right{
            width: 20%;
            margin-left: auto;
            border-left: 1px solid #cccccc;
            height: 100%;
            .btn-div{
                width: 200px;
                height: 300px;
                margin: 0 auto;
                margin-top: 240px;
                div{
                    text-align: center;
                    margin: 30px 0;
                    user-select: none;
                }
            }
        }
    }
}
/deep/.ivu-menu{
    position: static;
}
</style>