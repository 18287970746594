<template>
  <div class="form-div">
     <div><Checkbox v-model="isCheck" @on-change="changeCheck">低保申请信息</Checkbox></div>
     <Form v-show="isCheck" class="isForm" label-position="right" :label-width="170">
       <!-- <FormItem class="isNoWidth">
              <span slot="label">低保证号码:</span>
              <Input placeholder="请输入您的低保证号码" :maxlength="50" v-model="formDataAllowan.basiclivingNo" />
        </FormItem> -->
       <FormItem class="isNoWidth">
             <span slot="label">低保救助对象流动情况:</span>
              <Select clearable v-model="formDataAllowan.areaIn" >
                <Option
                v-for="item in areaInType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
        </FormItem>
       <FormItem class="isWidth">
             <span slot="label">残疾类型:</span>
              <Select clearable v-model="formDataAllowan.disabledType" >
                <Option
                v-for="item in disabledType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
        </FormItem>
       <FormItem class="isWidth">
             <span slot="label">低保救助身份:</span>
              <Select clearable v-model="formDataAllowan.reliefType" >
                <Option
                v-for="item in reliefType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
        </FormItem>
       <FormItem class="isWidth">
             <span slot="label">低保救助批准时间:</span>
             <DatePicker type="date" v-model="formDataAllowan.approveDate"></DatePicker>
        </FormItem>
       <FormItem class="isWidth">
             <span slot="label">低保救助时限:</span>
             <Input placeholder="请输入低保救助时限" :maxlength="50" v-model="formDataAllowan.reliefTimelimit" />
        </FormItem>
       <FormItem class="isWidth">
             <span slot="label">低保救助结束时间:</span>
             <DatePicker type="date" v-model="formDataAllowan.endDate"></DatePicker>
        </FormItem>
         <!-- <FormItem class="isWidth">
             <span slot="label">低保救助开户行:</span>
             <Input placeholder="请输入低保救助开户行" :maxlength="50" v-model="formDataAllowan.bankName" />
        </FormItem> -->
         <!-- <FormItem class="isWidth">
             <span slot="label">低保救助银行账号:</span>
             <Input placeholder="请输入低保救助银行账号"  v-model="formDataAllowan.bankAccount" />
        </FormItem> -->
         <FormItem class="isWidth">
             <span slot="label">低保救助医疗保险:</span>
             <i-switch v-model="formDataAllowan.medicalInsurance" true-value='1' false-value="2" >
                <span slot="open">有</span>
                <span slot="close">无</span>
            </i-switch>
             <!-- <Input placeholder="请输入低保救助医疗保险" v-model="formDataAllowan.medicalInsurance" /> -->
        </FormItem>
         <FormItem class="isWidth">
             <span slot="label">低保救助养老保险:</span>
             <i-switch v-model="formDataAllowan.reliefInsurance" true-value='1' false-value="2" >
                <span slot="open">有</span>
                <span slot="close">无</span>
            </i-switch>
        </FormItem>
        <FormItem class="isWidth">
             <span slot="label">低保救助房屋说明:</span>
               <Select clearable v-model="formDataAllowan.reliefHouseDesc" >
                <Option
                v-for="item in reliefHouseType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
        </FormItem>
         <FormItem class="isNoWidth">
              <span slot="label">低保救助情况说明:</span>
              <Input placeholder="请输入低保救助情况说明" type="textarea" v-model="formDataAllowan.reliefDesc" />
        </FormItem>
     </Form>
  </div>
</template>

<script>
export default {
  props:{
    allowCheck:{
      type:Boolean,
      default:()=>{
        return false
      }
    },
    formDataAllowan:{
      type:Object,
      default:()=>{
        return {}
      }
    },
     userId:{
            type:Object,
            default:()=>{
                return ''
            }
        },
  },
  data(){
    return{
      isCheck:false,
      areaInType:[
        //  {dictKey:'1',dictValue:'辖内'},
        //  {dictKey:'2',dictValue:'辖内独居'},
        //  {dictKey:'3',dictValue:'辖外'},
        //  {dictKey:'4',dictValue:'辖外独居'},
        //  {dictKey:'5',dictValue:'住院'},
      ],
      disabledType:[],
      reliefType:[],
      reliefInsuranceType:[
         {dictKey:'1',dictValue:'无'},
         {dictKey:'2',dictValue:'暂停'},
         {dictKey:'3',dictValue:'自定义'},
      ],
      selectModal:false,
      selectTip:false,
      reliefHouseType:[]//低保救助房屋说明
    }
  },
  created(){
    this.getDicType('DISABILITY_STATUS','disabledType')
    this.getDicType('RESCUE_STATUS','reliefType')
    this.getDicType('HOUSING_DESCRIPTION ','bankName')
    this.getDicType('LIVING_SITUATION ','areaInType')
    this.getDicType('HOUSING_DESCRIPTION ','reliefHouseType')
    this.getDetail()
    if(this.allowCheck == true){
      this.isCheck = true
    }
  },
  methods:{ 
    changeCheck(val){
      this.isCheck = val
      this.$emit('changeAllow',val)
      // console.log(val);
    },
    // 获取字典接口的公共方法
        getDicType(type,model){
            this.$get('/voteapi/api/common/sys/findDictByDictType',{
                dictType:type
            }).then(res=>{
                this[model] = res.dataList
            })
        },
        getDetail(){
          if(this.userId && this.userId != ''){
            this.$get('/syaa/api/syuser/pc/residentArchive/selectArchiveBasicliving',{
              userId:this.userId,
              // familyId:this.familyId
            }).then(res=>{
              if(res.code == 200){
                  this.$emit('getAllow',res.data)
              }else{
                this.$Message.error({
                  content:'低保申请信息获取失败',
                  background:true
                })
                return
              }
            })
          }
          
        }
  },
   watch:{
     allowCheck:{
       hanlder(val,newVal){
         if(val){
           this.isCheck = val
         }
       }
     }
   }
}
</script>

<style lang="less" scoped>
.form-div{
    width:100%;
    user-select: none;
    .isForm{
        display: flex;
        flex-wrap: wrap;
        .isWidth{
        width: 40%;
        margin-right: 40px;
        }
        .isNoWidth{
            width: 100%;
        }
    }
    
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
/deep/.ivu-date-picker{
    display: inline;
}
.none{
  /deep/.ivu-form-item-content{
  display: flex;
  }
}
.select-div{
  .item-div{
    display: flex;
    flex-wrap: wrap;
    .item{
      width: 45%;
      margin: 10px;
    }
  }
  
}

</style>