<template>
       <div class="form-div">
      <Form class="isForm" label-position="right" :label-width="120">
          <FormItem class="isWidth">
              <span slot="label" >是否户主:</span>
              <RadioGroup v-model="formDataPerson.household" @on-change="changeRadio">
                <Radio label="1">
                    是
                </Radio>
                <Radio label="2">
                    否
                </Radio>
              </RadioGroup>
              <!-- <i-switch  v-model="formDataPerson.household"  /> -->
          </FormItem>
          <!-- <FormItem class="isWidth">
              <span slot="label" >户口簿号码:</span>
              <Input placeholder="请输入您的户口簿号码" :maxlength="20" v-model="formDataPerson.householdCode"  />
          </FormItem> -->
          <FormItem class="isWidth" v-if="formDataPerson.household != true">
              <span slot="label" >与户主关系:</span>
              <Select v-model="formDataPerson.householdRelation" clearable >
                <Option
                v-for="item in householdType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
          </FormItem>
          <FormItem class="isWidth" >
              <span slot="label" >户籍身份类型:</span>
              <Select v-model="formDataPerson.registeredType" clearable >
                <Option
                v-for="item in registeredType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label" >居住状况:</span>
              <Select v-model="formDataPerson.reside" clearable>
               <Option
                v-for="item in resideType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label" >文化程度:</span>
              <Select v-model="formDataPerson.eduDegree" clearable >
                <Option
                v-for="item in eduDegreeType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label" >婚姻状况:</span>
              <Select v-model="formDataPerson.marital" clearable >
                <Option
                v-for="item in maritalType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
          </FormItem> 
          <FormItem class="isWidth">
              <span slot="label" >政治面貌:</span>
              <Select v-model="formDataPerson.political" clearable >
                <Option
                v-for="item in politicalType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label" >结婚时间:</span>
             <DatePicker type="date" v-model="formDataPerson.maritalDate"></DatePicker>
          </FormItem>
          <!-- <FormItem class="isWidth">
              <span slot="label" >身高（cm）:</span>
             <Input placeholder="请输入您的身高，单位是厘米" :maxlength="3" v-model="formDataPerson.height"  />
          </FormItem> -->
          <!-- <FormItem class="isWidth">
              <span slot="label" >血型:</span>
              <Select v-model="formDataPerson.bloodType" clearable >
                <Option
                v-for="item in booldType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
          </FormItem> -->
           <FormItem class="isWidth">
              <span slot="label" >工作单位:</span>
             <Input placeholder="请输入您的工作单位"  v-model="formDataPerson.workUnit"  />
          </FormItem>
           <FormItem class="isWidth">
              <span slot="label" >经济状况:</span>
             <Select v-model="formDataPerson.economy" clearable>
                <Option
                v-for="item in economyType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
                <!-- <Option value="1">特困</Option>
                <Option value="2">普通家庭</Option>
                <Option value="3">困难家庭</Option> -->
              </Select>
          </FormItem>
           <FormItem class="isWidth">
              <span slot="label" >宗教信仰:</span>
             <Select v-model="formDataPerson.religion" clearable>
               <Option
                v-for="item in religionType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
          </FormItem>
           <!-- <FormItem class="isWidth">
              <span slot="label" >重点人员及类型:</span>
             <Select v-model="formDataPerson.importantPerson" clearable>
               <Option
                v-for="item in importType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
          </FormItem> -->
           <FormItem class="isWidth">
              <span slot="label" >是否为退休人员:</span>
              <RadioGroup v-model="formDataPerson.retire">
                <Radio label="1">
                    是
                </Radio>
                <Radio label="2">
                    否
                </Radio>
              </RadioGroup>
              <!-- <i-switch v-model="formDataPerson.retire"  /> -->
          </FormItem>
           <!-- <FormItem class="isWidth">
              <span slot="label" >是否已发参选证:</span>
             <Select v-model="formDataPerson.election" clearable >
                <Option value="1">未发</Option>
                <Option value="2">已发</Option>
              </Select>
          </FormItem> -->
          <FormItem class="isWidth">
              <span slot="label" >是否有精神障碍:</span>
              <RadioGroup v-model="formDataPerson.psychosis">
                <Radio label="1">
                    是
                </Radio>
                <Radio label="2">
                    否
                </Radio>
              </RadioGroup>
              <!-- <i-switch v-model="formDataPerson.psychosis"  /> -->
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label" >有无犯罪记录:</span>
              <RadioGroup v-model="formDataPerson.crime">
                <Radio label="1">
                    是
                </Radio>
                <Radio label="2">
                    否
                </Radio>
              </RadioGroup>
              <!-- <i-switch v-model="formDataPerson.crime"  /> -->
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label" >是否承租户:</span>
              <RadioGroup v-model="formDataPerson.tenant">
                <Radio label="1">
                    是
                </Radio>
                <Radio label="2">
                    否
                </Radio>
              </RadioGroup>
              <!-- <i-switch v-model="formDataPerson.tenant"  /> -->
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label" >是否空挂户:</span>
              <RadioGroup v-model="formDataPerson.emptyHouse">
                <Radio label="1">
                    是
                </Radio>
                <Radio label="2">
                    否
                </Radio>
              </RadioGroup>
              <!-- <i-switch v-model="formDataPerson.emptyHouse"  /> -->
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label" >是否流动人员:</span>
              <RadioGroup v-model="formDataPerson.migrants">
                <Radio label="1">
                    是
                </Radio>
                <Radio label="2">
                    否
                </Radio>
              </RadioGroup>
              <!-- <i-switch v-model="formDataPerson.migrants"  /> -->
          </FormItem>
      </Form>
  </div>
</template>

<script>
export default {
  props:{
    formDataPerson:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    userId:{
            type:Object,
            default:()=>{
                return ''
            }
        },
  },
  data(){
    return{
      householdType:[], //与户主关系
      registeredType:[], //户籍身份类型
      booldType:[], //血型
      religionType:[], //宗教信仰
      importType:[],//重点人员及类型
      maritalType:[ //婚姻状况
      ],
      eduDegreeType:[ //文化水平
      ],
      politicalType:[ //政治面貌
      
      ],
      resideType:[ //居住状况
    
      ],
      economyType:[] //经济状况
    }
  },
  mounted(){
    this.getDetail()
    
  },
 async created(){
   await  this.getDicType('FAMILY_RELATIONSHIP','householdType')
   await  this.getDicType('ONLINE_HOME_USER_TYPE','registeredType')
   await  this.getDicType('BLOOD_TYPE','booldType')
   await  this.getDicType('RELIGION','religionType')
   await  this.getDicType('IMPORTANT_PERSON','importType')
   await  this.getDicType('MARITAL','maritalType')
   await  this.getDicType('EDUCATION_LEVEL','eduDegreeType')
   await  this.getDicType('USER_POLITICAL','politicalType')
   await  this.getDicType('LIVING_CONDITION','resideType')
   await  this.getDicType('ECONOMIC_STATUS','economyType') 
  //  this.economyType.unshift(this.economyType.pop())
  //  console.log('this.economyType',this.economyType);
   
  },
  methods:{
    // radiochange事件
    changeRadio(val){
      console.log(val);
    },
    // 获取字典接口的公共方法
       async getDicType(type,model){
           await this.$get('/voteapi/api/common/sys/findDictByDictType',{
                dictType:type
            }).then(res=>{
                this[model] = res.dataList
            })
        },
        getDetail(){
          if(this.userId && this.userId != ''){
            this.$get('/syaa/api/syuser/pc/residentArchive/selectUserInfo',{
              userId:this.userId,
              // familyId:this.familyId
            }).then(res=>{
              if(res.code == 200){
                  if(res.data){
                    // this.$nextTick(()=>{
                      // if(res.data.household == '1') res.data.household = '1'
                      // if(res.data.retire == '1') res.data.retire ='1'
                      // if(res.data.psychosis == '1') res.data.psychosis ='1'
                      // if(res.data.crime == '1') res.data.crime ='1'
                      // if(res.data.tenant == '1') res.data.tenant ='1'
                      // if(res.data.emptyHouse == '1') res.data.emptyHouse ='1'
                      // if(res.data.migrants == '1') res.data.migrants ='1'
                       this.$emit('getPerson',res.data)
                    // })
                   
                  }
                  
                  
              }else{
                this.$Message.error({
                  content:'人员详细信息获取失败',
                  background:true
                })
                return
              }
            })
          }
          
        }
  }

}
</script>

<style lang="less" scoped>
.form-div{
    width:100%;
    .isForm{
        display: flex;
        flex-wrap: wrap;
        .isWidth{
        width: 40%;
        margin-right: 40px;
        }
        .isNoWidth{
            width: 100%;
        }
    }
    
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
/deep/.ivu-date-picker{
    display: inline;
}
</style>